
.container {
    height: 100vh;
}
.tuLogo {
  position: absolute;
  top: 5vh;
  left: 90vw;
  height: 12vh;
  transform: rotate(330deg);
}
.video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.trust{
    position: absolute;
    letter-spacing: 0.2rem;
    top: 35vh;
    left: 35vw;
    color: white;
    font-size: 10rem;
}
.trust_text{
    animation: animate 4s linear forwards;
    display: inline-block;
    opacity: 0;
}

@keyframes animate{
    0%{
        opacity: 0;
        transform: rotateY(90deg);
        filter: blur(10px);
    }
    50%{
      opacity: 1;
      transform: rotateY(0deg);
      filter:blur(0px)
      
    }
    90%{
      opacity: 1;
      transform: rotateY(0deg);
      filter:blur(0px)
    }
    100%{
       opacity: 0;
        transform: rotateY(90deg);
        filter: blur(10px);
    }
}
.fade_in_main_div{
  background-color: rgba(58, 12, 163, 0.75);
  height: 45vh;
  width: 35vw;
  position: absolute;
  top: 15rem;
  animation: 2s slide-left 2s forwards;
  border-radius: 0.5rem;
  animation-delay: 4s;
  transform:translateX(100vw);
  flex-direction: column;
  display: flex;

}

.login_header{
  color: white;
  font-family: "Oswald", sans-serif;
  font-size: 2.8rem;
  text-align: center;
}
.login_subheader{
  color: #caf0f8;
  font-family: "Oswald", sans-serif;
  font-size: 1rem;
  text-align: center;
  padding-left:5rem;
  padding-right:5rem;
  padding-top: 0.25rem;

}
.fade_in_main_span{
  color: white;
  font-size: 10rem;
}

@keyframes slide-left {
  100% {
    transform:translateX(62vw);
  }
}
  .submit_button {
    cursor: pointer;
    margin-top: 2rem;
    border-radius: 10px;
    border-width: 0px;
    color: white !important;
    background-color: #4eb9d7;
    height: 3rem;
    font-size: 1.5rem;
  }

  
  .side_text{
    background-color: transparent;
    height: 45vh;
    width: 35vw;
    position: absolute;
    top: 15rem;
    animation: 2s slide-right 2s forwards;
    animation-delay: 4s;
    transform:translateX(-100vw);
    flex-direction: column;
    display: flex;
  
  }
  @keyframes slide-right {
    100% {
      transform:translateX(10vw);
    }
  }

  .know{
    color: #ffc300;
    font-family: "Oswald", sans-serif;
    font-size: 5.5rem;
    text-shadow: 1.5px 1.5px #ffaa00;
  }
  .side_text_regular{
    color: white;
    font-family: "Oswald", sans-serif;
    font-size: 3rem;
    font-variant: small-caps;
  }
  .side_text_sub{
    padding-top: 3rem;
    padding-bottom: 0;
 
  }
  .side_text_sub_sub{
    padding-top: 0rem;
    padding-left: 1.5rem;
  }
  

