.main-app-body{
    background-color: white;
    margin: 0;
    padding: 0;
}

.pageWrapper {
    box-sizing: border-box;
    min-height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
}

.pageHeader, .pageFooter {
    flex-grow: 0;
    flex-shrink: 0;
}

.pageBody {
    flex-grow: 1;
}

